.container {
    --gray-5: #c3c3c3;
    --gray-4: #585858;
}

:global(.light) .main {
    background-color: #ffffff;
}
:global(.dark) .main {
    background-color: #252525;
}

:global(.light) .more {
    background-color: #f6f6f6;
}
:global(.dark) .more {
    background-color: #333333;
}
