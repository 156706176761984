.cm-editor.cm-focused:not(.x) {
    outline: none;
}

.cm-editor .cm-scroller {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace;
}

.cm-editor .cm-line {
    padding-left: 0;
    padding-right: 0;
}

.cm-editor .cm-content {
    padding-top: 0;
    padding-bottom: 0;
}

.cm-editor {
    flex: 1 1 auto;
    overflow: auto !important;
}
