.container {
    --gray-5: #adadad;
    --gray-9: #575757;

    --gray-4: #919292;
    --gray-1: #fcfdfd;
}
:global(.light) .container {
    color: var(--gray-9);
}
:global(.dark) .container {
    color: var(--gray-1);
}

.container input[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin-top: 3px;
    margin-left: 3px;
    border-radius: 3px;
}

:global(.light) .container {
    background: #ffffff;
    border: 0.5px solid #b7b7b7;
    border-top-color: #a5a5a5;
    box-shadow: inset 0px 0.5px 1px rgba(0, 0, 0, 0.2);
}

:global(.dark) .container {
    background-image: linear-gradient(180deg, #616161 0%, #797979 100%);
    border: none;
    box-shadow: inset 0 0.5px 0 #7f7f7f, inset 0 1px 0 #666666,
        0 0.5px 0.5px #292929;
}

.container button,
.container select,
.container input[type="text"] {
    height: 23px;
    /* Safari and Firefox */
    outline: revert;
    border-radius: 4px;
}

:global(.light) .container button,
:global(.light) .container select,
:global(.light) .container input[type="text"] {
    background: linear-gradient(180deg, #fefefe 0%, #f1f1f1 100%);
    box-shadow: 0px 0.5px 0px #c6c6c6;
    border: solid 0.5px #c3c3c3;
    border-bottom-color: #a0a0a0;
    border-top-color: #cacaca;
}
:global(.dark) .container button,
:global(.dark) .container select,
:global(.dark) .container input[type="text"] {
    background: linear-gradient(180deg, #7a7a7a 0%, #757575 100%);
    box-shadow: inset 0 0.5px 0 #949494, inset 0 1px 0 #7f7f7f;
    border: solid 0.5px #4a4a4a;
}

:global(.light) .container button:active {
    background: linear-gradient(180deg, #e3e3e3 0%, #d4d4d4 100%);
}
:global(.dark) .container button:active {
    background: linear-gradient(180deg, #8f8d8d 0%, #818080 100%);
}

.box {
    height: 37px;
}

.main {
    /* left: 70 + 8 = 78 */
    padding: 0px 8px 0px 70px;
}

:global(.light) .main {
    background: linear-gradient(180deg, #e5e5e5 0%, #cdcdcd 100%);
    box-shadow: inset 0 0.5px 0 #f6f6f6, inset 0 1px 0 #ebebeb;
}

:global(.dark) .main {
    background: linear-gradient(180deg, #565757 0%, #464747 100%);
    box-shadow: inset 0 0.5px 0 #949494, inset 0 1px 0 #868787;
}

:global(.light) .more {
    background: #ececec;
}
:global(.dark) .more {
    background: #444444;
}
