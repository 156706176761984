.temp-selected .path,
.selected .path {
    stroke-width: 2px;
    stroke: #0da2e7;
}

.temp-selected .dot,
.selected .dot {
    fill: #0da2e7;
}
