@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@radix-ui/colors/gray.css";
@import "@radix-ui/colors/slate.css";
@import "@radix-ui/colors/blue.css";
@import "@radix-ui/colors/green.css";
@import "@radix-ui/colors/red.css";
@import "@radix-ui/colors/slateDark.css";
@import "@radix-ui/colors/grayDark.css";
@import "@radix-ui/colors/blueDark.css";
@import "@radix-ui/colors/greenDark.css";
@import "@radix-ui/colors/redDark.css";

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("./fonts/Inter-roman.var.woff2?v=3.19") format("woff2");
    font-named-instance: "Regular";
}

@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url("./fonts/Inter-italic.var.woff2?v=3.19") format("woff2");
    font-named-instance: "Italic";
}

button {
    cursor: auto;
}

svg {
    /* evil github icon inline */
    display: block !important;
}

@layer components {
    .grid-container-cols-auto-fill {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        grid-gap: 20px;
    }

    /* https://www.electronjs.org/docs/latest/tutorial/window-customization#set-custom-draggable-region */
    .app-drag {
        -webkit-app-region: drag;
    }

    .app-no-drag {
        -webkit-app-region: no-drag;
    }
}

body {
    /* Avoid Mac jumping */
    overflow: hidden;
    font-size: 14px;
}

body:not(#weak) {
    font-variant-numeric: tabular-nums;
    font-weight: 400;
}
